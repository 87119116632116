import axios from "axios";
import Cookies from "universal-cookie";

import {
    roleList,
    incomingShipments,
    orderToShip,
    productList,
    trackingId,
    tariffList,
    tenantList,
    userCommunicationAPI,
    confirmAuthorizedUser,
    orderToPrep,
    AdminTotals,
    quoteOrders,
} from "../api/warehouseAPI";
import {
    AvailableId,
    CartonsStoredResult,
    ContainerTariffItem,
    CreateCustomerCommunicationType,
    DataNotesType,
    FilterType,
    GetAllContainersResult,
    GetAllIncomingWithTotals,
    GetIncomingShipmentsResult,
    GetShipFromListResult,
    GetTenantsListType,
    GetUserListResult,
    GetUserRoleListResult,
    TPatchSubmitFinalWeightOrCbmReqData,
    IncomingDict,
    OutgoingDict,
    PatchProductListRequestType,
    PostAddressReqType,
    PrepOrdersResult,
    ProductListFileBodyType,
    SetAllContainersType,
    SetAvaibleIdListType,
    SetContainerTariffsListType,
    SetDownloadingTotalFileType,
    SetIncomingPrepOrderType,
    SetIncomingShipmentsAllType,
    SetIncomingShipmentsType,
    SetLoadingType,
    SetOutgoingTotalsType,
    SetProductListAllType,
    SetProductListType,
    SetShipFromListAllType,
    SetShipFromListType,
    SetShippedDateType,
    SetStoredPrepOrderType,
    SetTenantsListType,
    SetTenantTotalIncomingType,
    SetTenantTotalOutgoingType,
    SetTenantTotalStoredType,
    TSetTrackingIdAll,
    SetTrackingIdListType,
    TSetTrackingId,
    SetUserListAllType,
    SetUserListType,
    SetUserRoleListAllType,
    SetUserRoleListType,
    SetWarehouseListAllType,
    SetWarehouseListType,
    StoredDict,
    TGetOrdersToPrepResultsItem,
    TOrdersToPrepState,
    TOrdersToPrepStateItem,
    TFreightForwarderFilter,
    TPostPrepOrders,
    TSetLoadingGlobalSearch,
    TSetOrdersToPrep,
    TSetOrdersToPrepAll,
    UpdateDownloadInfoType,
    WarehouseActionTypes,
    WarehousesListResult,
    WarehouseThunkType,
    TOrdersToShipStateItem,
    TGetOrdersToShipResultsItem,
    TSetOrdersToShip,
    TSetOrdersToShipAll,
    TOrdersToShipState,
    TPostCompletedOrderToPrep,
    TPostCompletedOwnFFOrderToShip,
    TWarehouseFilters,
    TSetWarehouseFilters,
    TSetWarehouseFiltersData,
    TPastOutgoingOrders,
    TSetPastOutgoingOrders,
    TSetPastOutgoingOrdersAll,
    TPastOutgoingOrdersStateType,
    TSetPastIncomingOrders,
    TSetPastIncomingOrdersAll,
    TWarehouseMarkets,
    TPatchUpdateTrackingIdsReqData,
    TDashboardWarehouseLoadingStates,
    TSetLoadingStates,
    TPatchBulkPickedUpOrdersToShipReqData,
    TPatchBulkLoadedOrdersToShipReqData,
    TOrdersToShipStateAll,
    GetOrderToShipResult,
    TSetOrdersToShipBulkManagement,
    TPostOrdersToShipVerificationFile,
    TOrdersToPrepStateAll,
    TSetOrdersToPrepBulkManagement,
    TPostBulkCompletedOrdersToPrep,
    TQuoteOrders,
    TSetQuoteOrders,
    TPostCompletedQuoteOrderToPrep,
    TPatchQuoteOrders,
    TOrdersToTrackState,
    TOrdersToTrackStateAll,
    TQuoteOrdersFilter,
    TOrdersToTrackStateItem,
    TGetTrackingIdResultsItem,
    SetQuoteTrackingIdListType,
    TPatchUpdateQuoteTrackingIdsReqData,
    TPatchQuoteLoadedOrdersToShipReqData,
    TTariffManagementState,
    TTariffManagementResultItems,
    TSetTariffManagement,
    TSetTariffManagementAll,
    TTariffManagementStateItem,
    TWarehouseDashboardOrdering,
    TOrdersFilter,
    TQuoteOrdersStateItem,
    TQuoteOrdersState,
    TSetQuoteOrdersAll,
    TDashboardWarehouseFilters,
} from "./types/WarehouseTypes/warehouse.types";
import { Auth } from "./authReducer";
import { dashboardAPI } from "../api/addProductAPI";
import { displayError, displaySuccess, displayToastMessage } from "../hooks/useErrorHandler";
import { ADMIN_TABS_DEFAULT_ORDERING } from "../components/DashboardWarehouse/common/Constants";
import { TRequestLazySearchParams } from "./types/common/common.types";

export const SET_INCOMING_SHIPMENTS = "SET_INCOMING_SHIPMENTS";
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_INCOMING_SHIPMENTS_ALL = "SET_INCOMING_SHIPMENTS_ALL";
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";
export const SET_PRODUCT_LIST_ALL = "SET_PRODUCT_LIST_ALL";
export const SET_INCOMING_PREP_ORDERS_LIST = "SET_INCOMING_PRODUCT_LIST";
export const SET_TOTAL_STORED_PREP_ORDERS_LIST = "SET_TOTAL_STORED_PREP_ORDERS_LIST";
export const SET_TRACKING_ID_LIST = "SET_TRACKING_ID_LIST";
export const SET_TRACKING_ID_LIST_ALL = "SET_TRACKING_ID_LIST_ALL";
export const SET_OUTGOING_TOTAL = "SET_OUTGOING_TOTAL";
export const SET_WAREHOUSE_LIST = "SET_WAREHOUSE_LIST";
export const SET_WAREHOUSE_LIST_ALL = "SET_WAREHOUSE_LIST_ALL";
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_USER_LIST_ALL = "SET_USER_LIST_ALL";
export const SET_USER_ROLE_LIST = "SET_USER_ROLE_LIST";
export const SET_USER_ROLE_LIST_ALL = "SET_USER_ROLE_LIST_ALL";
export const SET_TENANT_TOTAL_STORED = "SET_TENANT_TOTAL_STORED";
export const SET_TENANT_TOTAL_INCOMING = "SET_TENANT_TOTAL_INCOMING";
export const SET_TENANT_TOTAL_OUTGOING = "SET_TENANT_TOTAL_OUTGOING";
export const SET_TARIF_CALCULATED = "SET_TARIF_CALCULATED";
export const SET_SHIP_FROM_LIST = "SHIP_FROM_LIST";
export const SET_SHIP_FROM_LIST_ALL = "SHIP_FROM_LIST_ALL";
export const SET_AVAIBLE_ID_LIST = "SET_AVAIBLE_ID_LIST";
export const SET_TENANTS_LIST = "SET_TENANTS_LIST";
export const SET_PAST_OUTGOING_ORDERS = "SET_PAST_OUTGOING_ORDERS";
export const SET_PAST_OUTGOING_ORDERS_ALL = "SET_PAST_OUTGOING_ORDERS_ALL";
export const SET_PAST_INCOMING_ORDERS = "SET_PAST_INCOMING_ORDERS";
export const SET_PAST_INCOMING_ORDERS_ALL = "SET_PAST_INCOMING_ORDERS_ALL";
export const SET_ALL_CONTAINERS = "SET_ALL_CONTAINERS";
export const SET_DOWNLOADING_TOTAL_FILE = "SET_DOWNLOADING_TOTAL_FILE";
export const SET_CONTAINER_TARIFFS_LIST = "SET_CONTAINER_TARIFFS_LIST";
export const SET_LOADING_GLOBAL_SEARCH = "SET_LOADING_GLOBAL_SEARCH";
export const SET_ORDERS_TO_PREP = "SET_ORDERS_TO_PREP";
export const SET_ORDERS_TO_PREP_ALL = "SET_ORDERS_TO_PREP_ALL";
export const SET_ORDERS_TO_SHIP = "SET_ORDERS_TO_SHIP";
export const SET_ORDERS_TO_SHIP_ALL = "SET_ORDERS_TO_SHIP_ALL";
export const SET_WAREHOUSE_FILTERS = "SET_WAREHOUSE_FILTERS";
export const SET_LOADING_STATES = "SET_LOADING_STATES";
export const SET_ORDERS_TO_SHIP_BULK_MANAGEMENT = "SET_ORDERS_TO_SHIP_BULK_MANAGEMENT";
export const SET_ORDERS_TO_PREP_BULK_MANAGEMENT = "SET_ORDERS_TO_PREP_BULK_MANAGEMENT";
export const SET_QUOTE_ORDERS = "SET_QUOTE_ORDERS";
export const SET_QUOTE_ORDERS_ALL = "SET_QUOTE_ORDERS_ALL";
export const SET_TARIFF_MANAGEMENT = "SET_TARIFF_MANAGEMENT";
export const SET_TARIFF_MANAGEMENT_ALL = "SET_TARIFF_MANAGEMENT_ALL";

const defaultDashboardWarehouseFilters: TDashboardWarehouseFilters = {
    ordersToPrep: "skudrop",
    ordersToShip: "skudrop",
    trackingIdEntry: "skudrop",
    tariffManagement: "skudrop",
    quoteOrders: "quote_requests",
};

type InitialStateType = {
    incomingShipments: null | GetIncomingShipmentsResult[];
    incomingShipmentsAll: null | GetIncomingShipmentsResult[];
    incomingShipmentsSuspended: null | GetIncomingShipmentsResult[];
    incomingShipmentsSuspendedAll: null | GetIncomingShipmentsResult[];
    productList: null | CartonsStoredResult[];
    productListAll: null | CartonsStoredResult[];
    isLoading: boolean;
    incomingPrepOrders: null | IncomingDict;
    totalStoredPrepOrders: null | StoredDict;
    trackingList: TOrdersToTrackState;
    trackingListAll: TOrdersToTrackStateAll;
    outgoingTotals: null | OutgoingDict;
    tenantTotalIncoming: null | IncomingDict;
    tenantTotalStored: null | StoredDict;
    tenantTotalOutgoing: null | OutgoingDict;
    warehouseList: null | WarehousesListResult[];
    warehouseListAll: null | WarehousesListResult[];
    shipFromList: null | GetShipFromListResult[];
    shipFromListAll: null | GetShipFromListResult[];
    avaibleIdList: null | AvailableId[];
    userList: null | GetUserListResult[];
    userListAll: null | GetUserListResult[];
    usersRole: null | GetUserRoleListResult[];
    usersRoleAll: null | GetUserRoleListResult[];
    tenantsList: null | GetTenantsListType[];
    pastIncomingOrders: GetAllIncomingWithTotals | null;
    pastIncomingOrdersAll: GetAllIncomingWithTotals | null;
    pastOutgoingOrders: TPastOutgoingOrdersStateType;
    pastOutgoingOrdersAll: TPastOutgoingOrdersStateType;
    allContainers: null | GetAllContainersResult[];
    downloadingTotalFile: boolean;
    containerTariffsList: null | ContainerTariffItem[];
    isLoadingGlobalSearch: boolean;
    orders_to_prep: TOrdersToPrepState;
    orders_to_prep_all: TOrdersToPrepStateAll;
    orders_to_ship: TOrdersToShipState;
    orders_to_ship_all: TOrdersToShipStateAll;
    quote_orders: TQuoteOrdersState;
    quote_orders_all: TQuoteOrdersState;
    tariff_management: TTariffManagementState;
    tariff_management_all: TTariffManagementState;
    warehouseFilters: TWarehouseFilters;
    loadingStates: TDashboardWarehouseLoadingStates;
};

let initialState: InitialStateType = {
    incomingShipments: null,
    incomingShipmentsAll: null,
    incomingShipmentsSuspended: null,
    incomingShipmentsSuspendedAll: null,
    productList: null,
    productListAll: null,
    isLoading: false,
    incomingPrepOrders: null,
    totalStoredPrepOrders: null,
    trackingList: {
        skudrop: null,
        quote_orders: null,
    },
    trackingListAll: {
        skudrop: null,
        quote_orders: null,
    },
    outgoingTotals: null,
    tenantTotalIncoming: null,
    tenantTotalStored: null,
    tenantTotalOutgoing: null,
    warehouseList: null,
    warehouseListAll: null,
    shipFromList: null,
    shipFromListAll: null,
    avaibleIdList: null,
    userList: null,
    userListAll: null,
    usersRole: null,
    usersRoleAll: null,
    tenantsList: null,
    pastIncomingOrders: null,
    pastIncomingOrdersAll: null,
    pastOutgoingOrders: {
        skudrop: null,
        own_ff: null,
    },
    pastOutgoingOrdersAll: {
        skudrop: null,
        own_ff: null,
    },
    allContainers: null,
    downloadingTotalFile: false,
    containerTariffsList: null,
    isLoadingGlobalSearch: false,
    orders_to_prep: {
        skudrop: null,
        own_ff: null,
        quote_orders: null,
    },
    orders_to_prep_all: {
        skudrop: null,
        own_ff: null,
        quote_orders: null,
        bulk_management: null,
    },
    orders_to_ship: {
        skudrop: null,
        own_ff: null,
        quote_orders: null,
    },
    orders_to_ship_all: {
        skudrop: null,
        own_ff: null,
        quote_orders: null,
        bulk_management: null,
        verification_file: null,
    },
    tariff_management: {
        skudrop: null,
        quote_orders: null,
    },
    tariff_management_all: {
        skudrop: null,
        quote_orders: null,
    },
    quote_orders: {
        quote_requests: null,
        pending_quote_requests: null,
    },
    quote_orders_all: {
        quote_requests: null,
        pending_quote_requests: null,
    },
    warehouseFilters: {
        markets: {
            incomingShipments: "ALL",
            ordersToPrep: "ALL",
            ordersToShip: "ALL",
            trackingIdEntry: "ALL",
            tariffManagement: "ALL",
        },
        filtersFF: localStorage.getItem("filtersFF")
            ? { ...defaultDashboardWarehouseFilters, ...JSON.parse(localStorage.getItem("filtersFF") as string) }
            : defaultDashboardWarehouseFilters,
    },
    loadingStates: {
        isLoadingIncomingCartons: false,
        isLoadingOrdersToPrep: false,
        isLoadingOrdersToShip: false,
        isLoadingTrackingIdEntry: false,
        isLoadingTariffManagement: false,
        isLoadingStoredProducts: false,
        isLoadingQuoteOrders: false,
    },
};

const warehouseReducer = (state = initialState, action: WarehouseActionTypes): InitialStateType => {
    switch (action.type) {
        case SET_CONTAINER_TARIFFS_LIST: {
            return {
                ...state,
                containerTariffsList: action.data,
            };
        }
        case SET_PAST_INCOMING_ORDERS: {
            return {
                ...state,
                pastIncomingOrders: action.data,
            };
        }
        case SET_PAST_INCOMING_ORDERS_ALL: {
            return {
                ...state,
                pastIncomingOrdersAll: action.data,
            };
        }
        case SET_PAST_OUTGOING_ORDERS: {
            return {
                ...state,
                pastOutgoingOrders: { ...state.pastOutgoingOrders, [action.ffType]: action.data },
            };
        }
        case SET_PAST_OUTGOING_ORDERS_ALL: {
            return {
                ...state,
                pastOutgoingOrdersAll: { ...state.pastOutgoingOrdersAll, [action.ffType]: action.data },
            };
        }
        case SET_TENANT_TOTAL_STORED: {
            return {
                ...state,
                tenantTotalStored: action.data,
            };
        }
        case SET_TENANT_TOTAL_INCOMING: {
            return {
                ...state,
                tenantTotalIncoming: action.data,
            };
        }
        case SET_TENANT_TOTAL_OUTGOING: {
            return {
                ...state,
                tenantTotalOutgoing: action.data,
            };
        }
        case SET_USER_ROLE_LIST: {
            return {
                ...state,
                usersRole: action.data,
                isLoading: false,
            };
        }
        case SET_USER_ROLE_LIST_ALL: {
            return {
                ...state,
                usersRoleAll: action.data,
                isLoading: false,
            };
        }
        case SET_USER_LIST: {
            return {
                ...state,
                userList: action.data,
                isLoading: false,
            };
        }
        case SET_USER_LIST_ALL: {
            return {
                ...state,
                userListAll: action.data,
                isLoading: false,
            };
        }
        case SET_WAREHOUSE_LIST: {
            return {
                ...state,
                warehouseList: action.data,
                isLoading: false,
            };
        }
        case SET_WAREHOUSE_LIST_ALL: {
            return {
                ...state,
                warehouseListAll: action.data,
                isLoading: false,
            };
        }
        case SET_SHIP_FROM_LIST: {
            return {
                ...state,
                shipFromList: action.data,
                isLoading: false,
            };
        }
        case SET_SHIP_FROM_LIST_ALL: {
            return {
                ...state,
                shipFromListAll: action.data,
                isLoading: false,
            };
        }
        case SET_AVAIBLE_ID_LIST: {
            return {
                ...state,
                avaibleIdList: action.data,
                isLoading: false,
            };
        }
        case SET_INCOMING_SHIPMENTS: {
            return {
                ...state,
                incomingShipments: action.incoming,
                incomingShipmentsSuspended: action.suspended,
            };
        }
        case SET_INCOMING_SHIPMENTS_ALL: {
            return {
                ...state,
                incomingShipmentsAll: action.incoming,
                incomingShipmentsSuspendedAll: action.suspended,
            };
        }
        case SET_PRODUCT_LIST: {
            return {
                ...state,
                productList: action.data,
            };
        }
        case SET_PRODUCT_LIST_ALL: {
            return {
                ...state,
                productListAll: action.data,
            };
        }
        case SET_TRACKING_ID_LIST: {
            return {
                ...state,
                trackingList: { ...state.trackingList, [action.option]: action.data },
            };
        }
        case SET_TRACKING_ID_LIST_ALL: {
            return {
                ...state,
                trackingListAll: { ...state.trackingList, [action.option]: action.data },
            };
        }
        case SET_OUTGOING_TOTAL: {
            return {
                ...state,
                outgoingTotals: action.data,
                isLoading: false,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_INCOMING_PREP_ORDERS_LIST: {
            return {
                ...state,
                incomingPrepOrders: action.data,
            };
        }

        case SET_TOTAL_STORED_PREP_ORDERS_LIST: {
            return {
                ...state,
                totalStoredPrepOrders: action.data,
            };
        }
        case SET_TENANTS_LIST: {
            return {
                ...state,
                tenantsList: action.data,
            };
        }
        case SET_ALL_CONTAINERS: {
            return {
                ...state,
                allContainers: action.data,
            };
        }
        case SET_DOWNLOADING_TOTAL_FILE: {
            return {
                ...state,
                downloadingTotalFile: action.data,
            };
        }
        case SET_LOADING_GLOBAL_SEARCH: {
            return {
                ...state,
                isLoadingGlobalSearch: action.data,
            };
        }
        case SET_ORDERS_TO_PREP: {
            return {
                ...state,
                orders_to_prep: { ...state.orders_to_prep, [action.ffType]: action.data },
            };
        }
        case SET_ORDERS_TO_PREP_ALL: {
            return {
                ...state,
                orders_to_prep_all: { ...state.orders_to_prep_all, [action.ffType]: action.data },
            };
        }
        case SET_ORDERS_TO_SHIP: {
            return {
                ...state,
                orders_to_ship: { ...state.orders_to_ship, [action.ffType]: action.data },
            };
        }
        case SET_ORDERS_TO_SHIP_ALL: {
            return {
                ...state,
                orders_to_ship_all: { ...state.orders_to_ship_all, [action.ffType]: action.data },
            };
        }
        case SET_ORDERS_TO_SHIP_BULK_MANAGEMENT: {
            return {
                ...state,
                orders_to_ship_all: { ...state.orders_to_ship_all, [action.option]: action.data },
            };
        }
        case SET_ORDERS_TO_PREP_BULK_MANAGEMENT: {
            return {
                ...state,
                orders_to_prep_all: { ...state.orders_to_prep_all, bulk_management: action.data },
            };
        }
        case SET_TARIFF_MANAGEMENT: {
            return {
                ...state,
                tariff_management: { ...state.tariff_management, [action.option]: action.data },
            };
        }
        case SET_TARIFF_MANAGEMENT_ALL: {
            return {
                ...state,
                tariff_management_all: { ...state.tariff_management_all, [action.option]: action.data },
            };
        }
        case SET_QUOTE_ORDERS: {
            return {
                ...state,
                quote_orders: { ...state.quote_orders, [action.option]: action.data },
            };
        }
        case SET_QUOTE_ORDERS_ALL: {
            return {
                ...state,
                quote_orders_all: { ...state.quote_orders_all, [action.option]: action.data },
            };
        }
        case SET_WAREHOUSE_FILTERS: {
            const filtersFF = { ...state.warehouseFilters.filtersFF, ...action.data?.filtersFF };

            const updatedData: TWarehouseFilters = {
                markets: { ...state.warehouseFilters.markets, ...action.data?.markets },
                filtersFF,
            };

            localStorage.setItem("filtersFF", JSON.stringify(filtersFF));

            return {
                ...state,
                warehouseFilters: updatedData,
            };
        }
        case SET_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        default:
            return state;
    }
};
export const SetPastIncomingOrders = (data: GetAllIncomingWithTotals): TSetPastIncomingOrders => ({
    type: SET_PAST_INCOMING_ORDERS,
    data,
});
export const SetPastIncomingOrdersAll = (data: GetAllIncomingWithTotals | null): TSetPastIncomingOrdersAll => ({
    type: SET_PAST_INCOMING_ORDERS_ALL,
    data,
});
export const SetPastOutgoingOrders = <TData extends TPastOutgoingOrders>(data: TData, ffType: TFreightForwarderFilter): TSetPastOutgoingOrders<TData> => ({
    type: SET_PAST_OUTGOING_ORDERS,
    ffType,
    data,
});
export const SetPastOutgoingOrdersAll = <TData extends TPastOutgoingOrders>(data: TData, ffType: TFreightForwarderFilter): TSetPastOutgoingOrdersAll<TData> => ({
    type: SET_PAST_OUTGOING_ORDERS_ALL,
    ffType,
    data,
});
export const SetTenantTotalStored = (data: StoredDict): SetTenantTotalStoredType => ({
    type: SET_TENANT_TOTAL_STORED,
    data,
});
export const SetTenantTotalIncoming = (data: IncomingDict): SetTenantTotalIncomingType => ({
    type: SET_TENANT_TOTAL_INCOMING,
    data,
});
export const SetTenantTotalOutgoing = (data: OutgoingDict): SetTenantTotalOutgoingType => ({
    type: SET_TENANT_TOTAL_OUTGOING,
    data,
});
export const SetUserRoleList = (data: GetUserRoleListResult[]): SetUserRoleListType => ({
    type: SET_USER_ROLE_LIST,
    data,
});
export const SetUserRoleAll = (data: GetUserRoleListResult[]): SetUserRoleListAllType => ({
    type: SET_USER_ROLE_LIST_ALL,
    data,
});
export const SetUserList = (data: GetUserListResult[]): SetUserListType => ({
    type: SET_USER_LIST,
    data,
});
export const SetUserListAll = (data: GetUserListResult[]): SetUserListAllType => ({
    type: SET_USER_LIST_ALL,
    data,
});
export const SetWarehouseList = (data: WarehousesListResult[]): SetWarehouseListType => ({
    type: SET_WAREHOUSE_LIST,
    data,
});
export const SetWarehouseListAll = (data: WarehousesListResult[]): SetWarehouseListAllType => ({
    type: SET_WAREHOUSE_LIST_ALL,
    data,
});
export const SetShipFromList = (data: GetShipFromListResult[]): SetShipFromListType => ({
    type: SET_SHIP_FROM_LIST,
    data,
});
export const SetShipFromListAll = (data: GetShipFromListResult[]): SetShipFromListAllType => ({
    type: SET_SHIP_FROM_LIST_ALL,
    data,
});
export const SetAvaibleIdList = (data: AvailableId[]): SetAvaibleIdListType => ({
    type: SET_AVAIBLE_ID_LIST,
    data,
});
export const SetIncomingShipments = (incoming: GetIncomingShipmentsResult[], suspended: GetIncomingShipmentsResult[]): SetIncomingShipmentsType => ({
    type: SET_INCOMING_SHIPMENTS,
    incoming,
    suspended,
});
export const SetIncomingShipmentsAll = (incoming: GetIncomingShipmentsResult[], suspended: GetIncomingShipmentsResult[]): SetIncomingShipmentsAllType => ({
    type: SET_INCOMING_SHIPMENTS_ALL,
    incoming,
    suspended,
});
export const SetProductList = (data: CartonsStoredResult[]): SetProductListType => ({
    type: SET_PRODUCT_LIST,
    data,
});
export const SetProductListAll = (data: CartonsStoredResult[]): SetProductListAllType => ({
    type: SET_PRODUCT_LIST_ALL,
    data,
});
export const SetTrackingIdList = <TData extends TOrdersToTrackStateItem<TGetTrackingIdResultsItem>>(option: TOrdersFilter, data: TData): TSetTrackingId<TData> => ({
    type: SET_TRACKING_ID_LIST,
    option,
    data,
});
export const SetTrackingIdListAll = <TData extends TOrdersToTrackStateItem<TGetTrackingIdResultsItem>>(option: TOrdersFilter, data: TData): TSetTrackingIdAll<TData> => ({
    type: SET_TRACKING_ID_LIST_ALL,
    option,
    data,
});
export const SetLoading = (loading: boolean): SetLoadingType => ({
    type: SET_LOADING,
    data: loading,
});
export const SetIncomingPrepOrder = (data: IncomingDict): SetIncomingPrepOrderType => ({
    type: SET_INCOMING_PREP_ORDERS_LIST,
    data: data,
});

export const SetStoredPrepOrder = (data: StoredDict): SetStoredPrepOrderType => ({
    type: SET_TOTAL_STORED_PREP_ORDERS_LIST,
    data: data,
});
export const SetOutgoingTotals = (data: OutgoingDict): SetOutgoingTotalsType => ({
    type: SET_OUTGOING_TOTAL,
    data: data,
});
export const SetTenantsList = (data: GetTenantsListType[]): SetTenantsListType => ({
    type: SET_TENANTS_LIST,
    data: data,
});
export const SetAllContainers = (data: GetAllContainersResult[]): SetAllContainersType => ({
    type: SET_ALL_CONTAINERS,
    data: data,
});
export const SetDownloadingTotalFile = (data: boolean): SetDownloadingTotalFileType => ({
    type: SET_DOWNLOADING_TOTAL_FILE,
    data: data,
});
export const SetContainerTariffsList = (data: ContainerTariffItem[]): SetContainerTariffsListType => ({
    type: SET_CONTAINER_TARIFFS_LIST,
    data: data,
});
export const SetLoadingGlobalSearch = (data: boolean): TSetLoadingGlobalSearch => ({
    type: SET_LOADING_GLOBAL_SEARCH,
    data: data,
});
export const SetOrdersToPrep = <TData extends TOrdersToPrepStateItem<TGetOrdersToPrepResultsItem>>(ffType: TFreightForwarderFilter, data: TData): TSetOrdersToPrep<TData> => ({
    type: SET_ORDERS_TO_PREP,
    ffType,
    data,
});
export const SetOrdersToPrepAll = <TData extends TOrdersToPrepStateItem<TGetOrdersToPrepResultsItem>>(
    ffType: TFreightForwarderFilter,
    data: TData
): TSetOrdersToPrepAll<TData> => ({
    type: SET_ORDERS_TO_PREP_ALL,
    ffType,
    data,
});
export const SetOrdersToShip = <TData extends TOrdersToShipStateItem<TGetOrdersToShipResultsItem>>(ffType: TFreightForwarderFilter, data: TData): TSetOrdersToShip<TData> => ({
    type: SET_ORDERS_TO_SHIP,
    ffType,
    data,
});
export const SetOrdersToShipAll = <TData extends TOrdersToShipStateItem<TGetOrdersToShipResultsItem>>(
    ffType: TFreightForwarderFilter,
    data: TData
): TSetOrdersToShipAll<TData> => ({
    type: SET_ORDERS_TO_SHIP_ALL,
    ffType,
    data,
});
export const SetWarehouseFilters = (data: TSetWarehouseFiltersData): TSetWarehouseFilters => ({
    type: SET_WAREHOUSE_FILTERS,
    data,
});

export const SetLoadingStates = (data: { [key in keyof TDashboardWarehouseLoadingStates]?: boolean }): TSetLoadingStates => ({
    type: SET_LOADING_STATES,
    data,
});

export const SetOrdersToShipBulkManagement = (option: "bulk_management" | "verification_file", data: GetOrderToShipResult[]): TSetOrdersToShipBulkManagement => ({
    type: SET_ORDERS_TO_SHIP_BULK_MANAGEMENT,
    option,
    data,
});

export const SetOrdersToPrepBulkManagement = (data: PrepOrdersResult[]): TSetOrdersToPrepBulkManagement => ({
    type: SET_ORDERS_TO_PREP_BULK_MANAGEMENT,
    data,
});

export const SetTariffManagement = <TData extends TTariffManagementStateItem<TTariffManagementResultItems>>(option: TOrdersFilter, data: TData): TSetTariffManagement<TData> => ({
    type: SET_TARIFF_MANAGEMENT,
    option,
    data,
});

export const SetTariffManagementAll = <TData extends TTariffManagementStateItem<TTariffManagementResultItems>>(
    option: TOrdersFilter,
    data: TData
): TSetTariffManagementAll<TData> => ({
    type: SET_TARIFF_MANAGEMENT_ALL,
    option,
    data,
});

export const SetQuoteOrders = <TData extends TQuoteOrdersStateItem<TQuoteOrders>>(option: TQuoteOrdersFilter, data: TData): TSetQuoteOrders<TData> => ({
    type: SET_QUOTE_ORDERS,
    option,
    data,
});

export const SetQuoteOrdersAll = <TData extends TQuoteOrdersStateItem<TQuoteOrders>>(option: TQuoteOrdersFilter, data: TData): TSetQuoteOrdersAll<TData> => ({
    type: SET_QUOTE_ORDERS_ALL,
    option,
    data,
});

export const getAdminsTotals = (cookies: Cookies): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await AdminTotals.getAdminTotals(cookies);
            console.log(response.data);
            dispatch(SetIncomingPrepOrder(response.data.incoming_dict));
            dispatch(SetOutgoingTotals(response.data.prepared_shipment_dict));
            dispatch(SetStoredPrepOrder(response.data.stored_dict));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
            if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
                displayError(e.response.data);
            }
        }
    };
};

export const ConfirmAuthorizedUserToTenant = (id: string, token: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await confirmAuthorizedUser.ConfirmAuthorizedUserToTenant(id, token);
            console.log(response);
            displaySuccess(response.data.text);
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
            if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
                displayError(e.response.data);
            }
        }
    };
};
export const getPastIncomingOrders = (cookies: Cookies, search: string, startDate: string, endDate: string, market: TWarehouseMarkets, sortBy: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingGlobalSearch(true));
            const response = await incomingShipments.getPastIncomingOrders(cookies, search, startDate, endDate, market, sortBy);
            dispatch(
                SetPastIncomingOrders({
                    results: response.data.results,
                    total_cbm: response.data.total_cbm,
                    total_ctn: response.data.total_ctn,
                })
            );
            dispatch(SetLoadingGlobalSearch(false));
        } catch (e) {
            console.log(e);
        }
    };
};
export const getPastIncomingOrdersAll = (
    cookies: Cookies,
    search: string,
    startDate: string,
    endDate: string,
    items: number,
    offset: number,
    market: TWarehouseMarkets,
    sortBy: string,
    hasNextItems: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await incomingShipments.getPastIncomingOrders(cookies, search, startDate, endDate, market, sortBy, items, offset);
            hasNextItems(!!response.data.next);
            dispatch(
                SetPastIncomingOrdersAll({
                    results: response.data.results,
                    total_cbm: response.data.total_cbm,
                    total_ctn: response.data.total_ctn,
                })
            );
        } catch (e) {
            console.log(e);
        }
    };
};
export const getPastOutgoingOrders = (
    cookies: Cookies,
    search: string,
    startDate: string,
    endDate: string,
    market: TWarehouseMarkets,
    sortBy: string,
    activeFF: TFreightForwarderFilter
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingGlobalSearch(true));
            if (activeFF === "skudrop") {
                const response = await orderToShip.getPastOutgoingOrders(cookies, search, startDate, endDate, market, sortBy);
                dispatch(
                    SetPastOutgoingOrders(
                        {
                            results: response.data.results,
                            total_number_of_cartons: response.data.total_number_of_cartons,
                            total_prep_cost: response.data.total_prep_cost,
                            total_shipping: response.data.total_shipping,
                            total_tariff_mount: response.data.total_tariff_mount,
                        },
                        activeFF
                    )
                );
            } else {
                const response = await orderToShip.getPastOwnFFOutgoingOrders(cookies, search, startDate, endDate, market, sortBy);
                dispatch(
                    SetPastOutgoingOrders(
                        {
                            results: response.data.results,
                            total_number_of_cartons: response.data.total_number_of_cartons,
                            total_prep_cost: response.data.total_prep_cost,
                            total_shipping: response.data.total_shipping,
                            total_tariff_mount: response.data.total_tariff_mount,
                        },
                        activeFF
                    )
                );
            }
            dispatch(SetLoadingGlobalSearch(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getPastOutgoingOrdersAll = (
    cookies: Cookies,
    search: string,
    startDate: string,
    endDate: string,
    items: number,
    offset: number,
    market: TWarehouseMarkets,
    sortBy: string,
    activeFF: TFreightForwarderFilter,
    hasNextItems: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            if (activeFF === "skudrop") {
                const response = await orderToShip.getPastOutgoingOrders(cookies, search, startDate, endDate, market, sortBy, items, offset);
                hasNextItems(!!response.data.next);
                dispatch(
                    SetPastOutgoingOrdersAll(
                        {
                            results: response.data.results,
                            total_number_of_cartons: response.data.total_number_of_cartons,
                            total_prep_cost: response.data.total_prep_cost,
                            total_shipping: response.data.total_shipping,
                            total_tariff_mount: response.data.total_tariff_mount,
                        },
                        activeFF
                    )
                );
            } else {
                const response = await orderToShip.getPastOwnFFOutgoingOrders(cookies, search, startDate, endDate, market, sortBy, items, offset);
                hasNextItems(!!response.data.next);
                dispatch(
                    SetPastOutgoingOrdersAll(
                        {
                            results: response.data.results,
                            total_number_of_cartons: response.data.total_number_of_cartons,
                            total_prep_cost: response.data.total_prep_cost,
                            total_shipping: response.data.total_shipping,
                            total_tariff_mount: response.data.total_tariff_mount,
                        },
                        activeFF
                    )
                );
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const deleteUserFromTennant = (cookies: Cookies, id: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            await tenantList.deleteUserFromTennant(cookies, id);
            dispatch(getUserList(cookies));
            dispatch(getWarehouseList(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while deleting the user");
            }
        }
    };
};
export const getUserRoleList = (cookies: Cookies): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await roleList.getUserRoleList(cookies);
            dispatch(SetUserRoleList(response.data.results));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};
export const getUserRoleListAll = (cookies: Cookies, items: number, offset: number, setProductAllNext: (value: boolean) => void, search: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await roleList.getUserRoleListAll(cookies, items, offset, search);
            dispatch(SetUserRoleAll(response.data.results));
            setProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};
export const updateUserRole = (cookies: Cookies, id: string, role: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            await roleList.updateUserRole(cookies, id, role);
            dispatch(Auth(cookies));
            dispatch(getUserRoleList(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while updating the user role");
            }
        }
    };
};
export const addUserRole = (cookies: Cookies, email: string, role: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await roleList.addUserRole(cookies, email, role);
            dispatch(getUserRoleList(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while creating a new user role");
            }
        }
    };
};
export const getUserList = (cookies: Cookies): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await tenantList.getUserList(cookies);
            dispatch(SetUserList(response.data.results));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};
export const getUserListAll = (
    cookies: Cookies,
    items: number,
    offset: number,
    setUserListAllNext: (value: boolean) => void,
    search: string,
    sortBy?: string
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await tenantList.getUserListAll(cookies, items, offset, search, sortBy);
            dispatch(SetUserListAll(response.data.results));
            setUserListAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};
export const getWarehouseList = (cookies: Cookies): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await tenantList.getWarehouseList(cookies);
            console.log(response.data.warehouses_list.results);
            dispatch(SetWarehouseList(response.data.warehouses_list.results));
            dispatch(SetTenantTotalStored(response.data.stored_dict));
            dispatch(SetTenantTotalIncoming(response.data.incoming_dict));
            dispatch(SetTenantTotalOutgoing(response.data.outgoing_dict));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};
export const getWarehouseListAll = (cookies: Cookies, items: number, offset: number, setProductAllNext: (value: boolean) => void, search: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await tenantList.getWarehouseListAll(cookies, items, offset, search);
            dispatch(SetWarehouseListAll(response.data.warehouses_list.results));
            setProductAllNext(response.data.warehouses_list.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};
export const getShipFromList = (cookies: Cookies): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await tenantList.getShipFromList(cookies);
            console.log(response.data);
            dispatch(SetShipFromList(response.data.results));
            dispatch(SetAvaibleIdList(response.data.available_ids));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};
export const getShipFromListAll = (cookies: Cookies, items: number, offset: number, setProductAllNext: (value: boolean) => void, search: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await tenantList.getShipFromListAll(cookies, items, offset, search);
            dispatch(SetShipFromListAll(response.data.results));
            dispatch(SetAvaibleIdList(response.data.available_ids));
            setProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};
export const postAddress = (data: PostAddressReqType, cookies: Cookies): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await tenantList.postAddress(cookies, data);
            console.log(response.data);
            dispatch(getShipFromList(cookies));
            dispatch(SetLoading(false));
            displaySuccess("The address has been successfully added");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while adding the address");
            }
        }
    };
};
export const patchAddress = (id: string, data: PostAddressReqType, cookies: Cookies): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await tenantList.patchAddress(id, data, cookies);
            console.log(response.data);
            dispatch(getShipFromList(cookies));
            dispatch(SetLoading(false));
            displaySuccess("The address has been successfully updated");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while updating the address");
            }
        }
    };
};

export const deleteAddress = (cookies: Cookies, id: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await tenantList.deleteAddress(cookies, id);
            console.log(response.data);
            dispatch(getShipFromList(cookies));
            dispatch(SetLoading(false));
            displaySuccess("The address has been successfully deleted");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while deleting the address");
            }
        }
    };
};
export const getIncomingShipments = (cookies: Cookies, incomingMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingIncomingCartons: true }));
            const response = await incomingShipments.getIncomingShipments(cookies, incomingMarket, ADMIN_TABS_DEFAULT_ORDERING);
            dispatch(SetIncomingShipments(response.data.incoming_shipments.results, response.data.suspended_incoming_shipments.results));
            dispatch(SetLoadingStates({ isLoadingIncomingCartons: false }));
        } catch (e) {
            console.log(e);
        }
    };
};
export const getIncomingShipmentsAll = (
    cookies: Cookies,
    incomingMarket: string,
    searchValue: string,
    sortBy: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    setProductAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await incomingShipments.getIncomingShipmentsAll(cookies, incomingMarket, searchValue, sortBy, items, offset);
            setProductAllNext(response.data.incoming_shipments.next !== null ? true : false);
            setProductAllSuspendedNext(response.data.suspended_incoming_shipments.next !== null ? true : false);
            dispatch(SetIncomingShipmentsAll(response.data.incoming_shipments.results, response.data.suspended_incoming_shipments.results));
        } catch (e) {
            console.log(e);
        }
    };
};
export const deleteIncomingShipments = (cookies: Cookies, id: string, incomingMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingIncomingCartons: true }));
            await incomingShipments.deleteIncomingShipments(cookies, id);
            dispatch(getIncomingShipments(cookies, incomingMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
                dispatch(SetLoadingStates({ isLoadingIncomingCartons: false }));
                displayError("You cannot delete this shipment because it is already partially delivered");
            } else if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingIncomingCartons: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const getOrdersToShip = (cookies: Cookies, shipMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));
            const response = await orderToShip.getOrdersToShip(cookies, shipMarket, ADMIN_TABS_DEFAULT_ORDERING);
            dispatch(SetOrdersToShip("skudrop", { orders: response.data.completed_orders.results, suspended_orders: response.data.suspended_completed_orders.results }));
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOrdersToShipAll = (
    cookies: Cookies,
    shipMarket: string,
    searchTerm: string,
    sortBy: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    setProductAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await orderToShip.getOrdersToShipAll(cookies, shipMarket, searchTerm, sortBy, items, offset);
            setProductAllNext(response.data.completed_orders.next !== null ? true : false);
            setProductAllSuspendedNext(response.data.suspended_completed_orders.next !== null ? true : false);
            dispatch(SetOrdersToShipAll("skudrop", { orders: response.data.completed_orders.results, suspended_orders: response.data.suspended_completed_orders.results }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getProductList = (cookies: Cookies, storedMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingStoredProducts: true }));
            const response = await productList.getProductList(cookies, storedMarket);
            dispatch(SetProductList(response.data.results));
            dispatch(SetLoadingStates({ isLoadingStoredProducts: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getProductListAll = (
    cookies: Cookies,
    items: number,
    offset: number,
    setProductListAllNext: (value: boolean) => void,
    search: string,
    storedMarket?: string
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await productList.getProductListAll(cookies, items, offset, search, storedMarket ? storedMarket : "ALL");
            dispatch(SetProductListAll(response.data.results));
            setProductListAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchProductList = (cookies: Cookies, data: PatchProductListRequestType, id: string, storedMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingStoredProducts: true }));
            await productList.patchProductList(cookies, data, id);
            dispatch(getProductList(cookies, storedMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingStoredProducts: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const deleteHSCodeStoredProduct = (cookies: Cookies, id: string, storedMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingStoredProducts: true }));
            await dashboardAPI.deleteHSCode(cookies, id);
            dispatch(getProductList(cookies, storedMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingStoredProducts: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const getTariffList = (
    cookies: Cookies,
    shipmentType: string | null,
    tariffMarket: string,
    setFilterType?: (data: { type: string; id: string }) => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));
            const response = await tariffList.getTariffList(cookies, shipmentType, tariffMarket);
            if (shipmentType === "AIR" && response.data.orders_to_manage.results.length === 0) {
                displayError("There are no Air Shipments");
            } else {
                dispatch(
                    SetTariffManagement("skudrop", {
                        orders: response.data.orders_to_manage.results,
                        suspended_orders: response.data.suspended_orders_to_manage.results,
                    })
                );
                setFilterType && setFilterType({ type: shipmentType ? shipmentType : "", id: "1" });
            }
            dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
        } catch (e) {
            console.log(e);
        }
    };
};
export const getTariffListAll = (
    cookies: Cookies,
    shipmentType: string | null,
    items: number,
    offset: number,
    search: string,
    tariffMarket: string,
    SetTariffListAllNext: (data: boolean) => void,
    setTariffListAllSuspendedNext: (data: boolean) => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await tariffList.getTariffListAll(cookies, shipmentType, items, offset, search, tariffMarket);
            dispatch(
                SetTariffManagementAll("skudrop", {
                    orders: response.data.orders_to_manage.results,
                    suspended_orders: response.data.suspended_orders_to_manage.results,
                })
            );
            SetTariffListAllNext(response.data.orders_to_manage.next !== null ? true : false);
            setTariffListAllSuspendedNext(response.data.suspended_orders_to_manage.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const calculateTariff = (cookies: Cookies, id: string[], filterType: FilterType, tariffMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));
            await dashboardAPI.calculateTariff(cookies.get("token"), id, filterType?.type);
            if (filterType?.type === "AIR") {
                dispatch(getTariffList(cookies, filterType.type, tariffMarket));
            } else if (filterType?.type.includes("Container")) {
                await dispatch(getTariffsForContainer(cookies.get("token"), filterType.id));
                dispatch(getItemsForContainer(cookies, filterType.id));
            } else {
                dispatch(getTariffList(cookies, null, tariffMarket));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
                displayError(e.response.data, "Something went wrong while calculating the tariff");
            }
        }
    };
};

export const patchTariffAmount = (cookies: Cookies, id: string, tariff_amount: string, tariffMarket: string, filterType?: FilterType): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));
            let response = await dashboardAPI.patchTariffAmount(id, tariff_amount, cookies.get("token"));
            if (response.data && filterType?.type === "AIR") {
                dispatch(getTariffList(cookies, filterType.type, tariffMarket));
            } else if (response.data && filterType?.type.includes("Container")) {
                await dispatch(getTariffsForContainer(cookies.get("token"), filterType.id));
                dispatch(getItemsForContainer(cookies, filterType.id));
            } else {
                dispatch(getTariffList(cookies, null, tariffMarket));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
                dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
                e.response.data["tariff_amount"] && displayError(e.response.data["tariff_amount"][0]);
                e.response.data[0] && displayError(e.response.data[0]);
            } else if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
                await dispatch(getTariffList(cookies, null, tariffMarket));
                displayError(e.response.data, "Something went wrong while updating the tariff amount");
            }
        }
    };
};

export const createAndConfirmPaymentIntent = (id: string[], cookies: Cookies, tariffMarket: string, tariffFiles?: File[] | null): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));
            await dashboardAPI.createAndConfirmPaymentIntent(id, cookies.get("token"), tariffFiles);
            await dispatch(getAllContainers(cookies));
            await dispatch(getTariffList(cookies, null, tariffMarket));
            displaySuccess("Charge successful");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                await dispatch(getTariffList(cookies, null, tariffMarket));
                displayError(e.response.data, "Something went wrong while creating payment intent");
            }
        }
    };
};

export const getAllContainers = (cookies: Cookies): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await tariffList.getAllContainers(cookies);
            dispatch(SetAllContainers(response.data.results));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getItemsForContainer = (cookies: Cookies, containerId: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));
            const response = await tariffList.getItemsForContainer(cookies, containerId);

            const outgoingData = response.data.orders_to_manage.map((el) => {
                return { ...el, outgoing_orders: el.outgoing_orders.results };
            });
            const suspendedData = response.data.suspended_orders_to_manage.map((el) => {
                return { ...el, outgoing_orders: el.outgoing_orders.results };
            });

            dispatch(
                SetTariffManagement("skudrop", {
                    orders: outgoingData,
                    suspended_orders: suspendedData,
                })
            );
            dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getAllItemsForContainer = (
    cookies: Cookies,
    containerId: string,
    items: number,
    offset: number,
    search: string,
    sortBy: string,
    SetTariffListAllNext: (data: boolean) => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await tariffList.getAllItemsForContainer(cookies, containerId, items, offset, search, sortBy);

            const outgoingData = response.data.orders_to_manage.map((el) => {
                return { ...el, outgoing_orders: el.outgoing_orders.results };
            });
            const suspendedData = response.data.suspended_orders_to_manage.map((el) => {
                return { ...el, outgoing_orders: el.outgoing_orders.results };
            });

            dispatch(
                SetTariffManagementAll("skudrop", {
                    orders: outgoingData,
                    suspended_orders: suspendedData,
                })
            );
            SetTariffListAllNext(response.data.orders_to_manage[0].outgoing_orders.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const getTrackingIdList = (cookies: Cookies, trackingMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));
            const response = await trackingId.getTrackingIdList(cookies, trackingMarket, ADMIN_TABS_DEFAULT_ORDERING);
            dispatch(SetTrackingIdList("skudrop", { orders: response.data.orders_to_track.results, suspended_orders: response.data.suspended_orders_to_track.results }));
            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getTrackingIdListAll = (
    cookies: Cookies,
    trackingMarket: string,
    search: string,
    sortBy: string,
    items: number,
    offset: number,
    setStoredProductAllNext: (value: boolean) => void,
    setProductAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await trackingId.getTrackingIdListAll(cookies, items, offset, search, sortBy, trackingMarket);
            dispatch(SetTrackingIdListAll("skudrop", { orders: response.data.orders_to_track.results, suspended_orders: response.data?.suspended_orders_to_track.results }));
            setStoredProductAllNext(!!response.data.orders_to_track.next);
            setProductAllSuspendedNext(!!response.data.suspended_orders_to_track.next);
        } catch (e) {
            console.log(e);
        }
    };
};

export const setTrackingIdList = (cookies: Cookies, data: SetTrackingIdListType, trackingMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));
            await trackingId.setTrackingIdList(cookies, data);
            dispatch(getTrackingIdList(cookies, trackingMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
                dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: false }));
                displayError(`The tracking number already exists.`);
            } else if (axios.isAxiosError(e) && e.response) {
                await dispatch(getTrackingIdList(cookies, trackingMarket));
                displayError(e.response.data);
            }
        }
    };
};

export const patchUpdateTrackingIds = (cookies: Cookies, data: TPatchUpdateTrackingIdsReqData, trackingMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));
            await trackingId.patchUpdateTrackingIds(cookies, data);
            dispatch(getTrackingIdList(cookies, trackingMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                await dispatch(getTrackingIdList(cookies, trackingMarket));
                displayError(e.response.data);
            }
        }
    };
};

export const setShippedDate = (cookies: Cookies, id: string, data: SetShippedDateType, trackingMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));
            await trackingId.setShippedDate(cookies, id, data);
            dispatch(getTrackingIdList(cookies, trackingMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: false }));
                displayError(e.response.data, "Something went wrong while updating the shipped date");
            }
        }
    };
};

export const ConfirmProductAction = (
    formData: FormData,
    cookies: Cookies,
    incomingMarket: string,
    prepMarket: string,
    storedMarket: string,
    onFilesUpload?: () => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingIncomingCartons: true }));
            await dashboardAPI.ConfirmProductAction(formData, cookies.get("token"));
            dispatch(getIncomingShipments(cookies, incomingMarket));
            dispatch(getProductList(cookies, storedMarket));
            dispatch(getOrdersToPrep(cookies, prepMarket));
            if (onFilesUpload) {
                onFilesUpload();
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                await dispatch(getIncomingShipments(cookies, incomingMarket));
                displayError(e.response.data, "Something went wrong during receiving cartons");
            }
        }
    };
};

export const AddNotesIncomingShipments = (data: DataNotesType, cookies: Cookies, incomingMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingIncomingCartons: true }));
            await dashboardAPI.AddNotesIncomingShipments(data, cookies.get("token"));
            dispatch(getIncomingShipments(cookies, incomingMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingIncomingCartons: false }));
                displayError(e.response.data, "Something went wrong while adding notes");
            }
        }
    };
};

export const UpdateDownloadInfo = (id: string, data: UpdateDownloadInfoType, cookies: Cookies, incomingMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingIncomingCartons: true }));
            await dashboardAPI.UpdateDownloadInfo(id, data, cookies.get("token"));
            dispatch(getIncomingShipments(cookies, incomingMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingIncomingCartons: false }));
                displayError(e.response.data, "Something went wrong while updating carton information");
            }
        }
    };
};

export const patchSubmitFinalWeightOrCbm = (
    cookies: Cookies,
    data: TPatchSubmitFinalWeightOrCbmReqData,
    prepMarket: string,
    shipMarket: string,
    trackingMarket: string,
    tariffMarket: string
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));
            await dashboardAPI.patchSubmitFinalWeightOrCbm(cookies, data);
            dispatch(getOrdersToShip(cookies, shipMarket));
            dispatch(getTrackingIdList(cookies, trackingMarket));
            dispatch(getTariffList(cookies, null, tariffMarket));
            dispatch(getOrdersToPrep(cookies, prepMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
                dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
                displayError(e.response.data);
            } else if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
                dispatch(getOrdersToShip(cookies, shipMarket));
                displayError(e.response.data);
            }
        }
    };
};

export const getOrdersToPrep = (cookies: Cookies, prepMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));
            let response = await orderToPrep.getOrdersToPrep(cookies, prepMarket, ADMIN_TABS_DEFAULT_ORDERING);
            dispatch(SetOrdersToPrep("skudrop", { orders: response.data.prep_orders.results, suspended_orders: response.data.suspended_prep_orders.results }));
            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOrdersToPrepAll = (
    cookies: Cookies,
    prepMarket: string,
    search: string,
    sortBy: string,
    items: number,
    offset: number,
    setStoredProductAllNext: (value: boolean) => void,
    setProductAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            let response = await orderToPrep.getOrdersToPrepAll(cookies, prepMarket, search, sortBy, items, offset);
            setStoredProductAllNext(!!response.data.prep_orders.next);
            setProductAllSuspendedNext(!!response.data.suspended_prep_orders.next);
            dispatch(SetOrdersToPrepAll("skudrop", { orders: response.data.prep_orders.results, suspended_orders: response.data.suspended_prep_orders.results }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postOrderToPrep = (reqData: TPostPrepOrders, cookies: Cookies, prepMarket: string, shipMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));
            await orderToPrep.postOrderToPrep(cookies, reqData);
            dispatch(getOrdersToPrep(cookies, prepMarket));
            dispatch(getOrdersToShip(cookies, shipMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                await dispatch(getOrdersToPrep(cookies, prepMarket));
                displayError(e.response.data, "Something went wrong while saving available cartons");
            }
        }
    };
};

export const patchOrderToShip = (id: string, shipment_id: string, cookies: Cookies, prepMarket: string, shipMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));
            await orderToShip.patchOrderToShip(id, shipment_id, cookies.get("token"));
            dispatch(getOrdersToShip(cookies, shipMarket));
            dispatch(getOrdersToPrep(cookies, prepMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
                dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
                displayError(e.response.data[0]);
            } else if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
                await dispatch(getOrdersToShip(cookies, shipMarket));
                displayError(e.response.data);
            }
        }
    };
};

export const checkIncomingShipment = (cookies: Cookies, item: GetIncomingShipmentsResult, incomingMarket: string, onSuccess: () => void): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            await incomingShipments.checkIncomingShipment(cookies.get("token"), item.id);
            onSuccess();
        } catch (e) {
            if (axios.isAxiosError(e) && e.response?.data) {
                displayError(e.response.data);
                dispatch(getIncomingShipments(cookies, incomingMarket));
            }
        }
    };
};

export const checkOutgoingShipment = (cookies: Cookies, item: PrepOrdersResult, prepMarket: string, onSuccess: () => void): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            await dashboardAPI.checkOutgoingShipment(cookies.get("token"), item.id);
            onSuccess();
        } catch (e) {
            if (axios.isAxiosError(e) && e.response?.data) {
                dispatch(getOrdersToPrep(cookies, prepMarket));
                displayError(e.response.data);
            }
        }
    };
};

export const getTenantsList = (token: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const res = await userCommunicationAPI.getTenantsList(token);
            if (res.data) {
                dispatch(SetTenantsList(res.data));
                dispatch(SetLoading(false));
            }
        } catch (e) {
            console.log(e);
            dispatch(SetLoading(false));
        }
    };
};

export const createCustomerCommunication = (data: CreateCustomerCommunicationType, token: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            await userCommunicationAPI.createCustomerCommunication(data, token);
            dispatch(SetLoading(false));
            displaySuccess("Communication request was created successfully");
        } catch (e) {
            dispatch(SetLoading(false));
            displayError("Something went wrong during sending communication request");
        }
    };
};

export const downloadAllIncomingShipments = (cookies: Cookies, search: string, startDate: string, endDate: string, market: string, sortBy: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetDownloadingTotalFile(true));
            const response = await incomingShipments.downloadIncomingTotalFile(cookies, search, startDate, endDate, market, sortBy);
            window.open(response.data.url);
            dispatch(SetDownloadingTotalFile(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetDownloadingTotalFile(false));
                displayError(e.response.data, "Something went wrong while downloading the file");
            }
        }
    };
};

export const downloadPastOutgoingTotals = (
    cookies: Cookies,
    search: string,
    startDate: string,
    endDate: string,
    market: string,
    sortBy: string,
    activeFF: TFreightForwarderFilter
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetDownloadingTotalFile(true));
            if (activeFF === "skudrop") {
                const response = await orderToShip.downloadSkudropOutgoingTotalFile(cookies, search, startDate, endDate, market, sortBy);
                window.open(response.data.url);
            } else {
                const response = await orderToShip.downloadOwnFFOutgoingTotalFile(cookies, search, startDate, endDate, market, sortBy);
                window.open(response.data.url);
            }
            dispatch(SetDownloadingTotalFile(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetDownloadingTotalFile(false));
                displayError(e.response.data, "Something went wrong while downloading the file");
            }
        }
    };
};

export const downloadProductListFile = (cookies: Cookies, body: ProductListFileBodyType): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingIncomingCartons: true }));
            const response = await AdminTotals.downloadProductListReport(cookies, body);
            window.open(response.data.url);
            dispatch(SetLoadingStates({ isLoadingIncomingCartons: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingIncomingCartons: false }));
                displayError(e.response.data, "Something went wrong while downloading the file");
            }
        }
    };
};

export const getTariffsForContainer = (token: string, id: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await dashboardAPI.getTariffsForContainer(token, id);
            dispatch(SetContainerTariffsList(response.data));
        } catch (e) {
            console.log(e);
        }
    };
};

export const checkOrderLabels = (cookies: Cookies, id: string, prepMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));
            await orderToPrep.checkOrderLabels(cookies, id);
            dispatch(getOrdersToPrep(cookies, prepMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingOrdersToPrep: false }));
                displayError(e.response.data, "Something went wrong while checking the order labels");
            }
        }
    };
};

// Quote Orders

export const getQuoteOrders = (queryParams: { pending: boolean }): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingQuoteOrders: true }));

            const { cookies } = getState().auth;

            const response = await quoteOrders.getQuoteOrders(cookies, { ...queryParams, ordering: ADMIN_TABS_DEFAULT_ORDERING });

            const dataField = queryParams.pending ? "pending_quote_requests" : "quote_requests";

            dispatch(
                SetQuoteOrders(dataField, {
                    orders: response.data[dataField].quote_requests.results,
                    suspended_orders: response.data[dataField].suspended_quote_requests.results,
                })
            );

            dispatch(SetLoadingStates({ isLoadingQuoteOrders: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const getQuoteOrdersAll = (
    queryParams: Partial<TRequestLazySearchParams> & { pending: boolean; ordering: TWarehouseDashboardOrdering },
    setProductAllNext: (value: boolean) => void,
    setProductAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            const response = await quoteOrders.getQuoteOrders(cookies, queryParams);

            const dataField = queryParams.pending ? "pending_quote_requests" : "quote_requests";

            dispatch(
                SetQuoteOrdersAll(dataField, {
                    orders: response.data[dataField].quote_requests.results,
                    suspended_orders: response.data[dataField].suspended_quote_requests.results,
                })
            );

            setProductAllNext(!!response.data[dataField].quote_requests.next);
            setProductAllSuspendedNext(!!response.data[dataField].suspended_quote_requests.next);
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const patchQuoteOrders = (cookies: Cookies, data: TPatchQuoteOrders, id: string, pending: boolean): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingQuoteOrders: true }));
            await quoteOrders.patchQuoteOrders(cookies, data, id);
            dispatch(getQuoteOrders({ pending }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

// Orders to Prep: Own FF

export const getOwnFFOrdersToPrep = (cookies: Cookies, prepMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));
            let response = await orderToPrep.getOwnFFOrdersToPrep(cookies, prepMarket, ADMIN_TABS_DEFAULT_ORDERING);
            dispatch(SetOrdersToPrep("own_ff", { orders: response.data.prep_orders.results, suspended_orders: response.data.suspended_prep_orders.results }));
            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOwnFFOrdersToPrepAll = (
    cookies: Cookies,
    prepMarket: string,
    search: string,
    sortBy: string,
    items: number,
    offset: number,
    setStoredProductAllNext: (value: boolean) => void,
    setProductAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            let response = await orderToPrep.getOwnFFOrdersToPrepAll(cookies, prepMarket, search, sortBy, items, offset);
            setStoredProductAllNext(!!response.data.prep_orders.next);
            setProductAllSuspendedNext(!!response.data.suspended_prep_orders.next);
            dispatch(SetOrdersToPrepAll("own_ff", { orders: response.data.prep_orders.results, suspended_orders: response.data.suspended_prep_orders.results }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchPickUpOwnFFOrderToPrep = (cookies: Cookies, prepMarket: string, id: string, data: { pick_up_time: string }): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));
            await orderToPrep.patchPickUpOwnFFOrderToPrep(cookies, id, data);
            dispatch(getOwnFFOrdersToPrep(cookies, prepMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response?.data) {
                dispatch(SetLoadingStates({ isLoadingOrdersToPrep: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const postCompletedOwnFFOrderToPrep = (cookies: Cookies, data: TPostCompletedOrderToPrep, activeMarkets: { prepMarket: string; shipMarket: string }): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { warehouseFilters } = getState().warehouse;

            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));

            await orderToPrep.postCompletedOwnFFOrderToPrep(cookies, data);

            dispatch(getOwnFFOrdersToPrep(cookies, activeMarkets.prepMarket));

            if (warehouseFilters.filtersFF.ordersToShip !== "skudrop") {
                dispatch(getOwnFFOrdersToShip(cookies, activeMarkets.shipMarket));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingOrdersToPrep: false, isLoadingOrdersToShip: false }));
                displayError(e.response.data);
            }
        }
    };
};

// Orders to Ship: Own FF

export const getOwnFFOrdersToShip = (cookies: Cookies, shipMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));
            let response = await orderToShip.getOwnFFOrdersToShip(cookies, shipMarket, ADMIN_TABS_DEFAULT_ORDERING);
            dispatch(SetOrdersToShip("own_ff", { orders: response.data.completed_orders.results, suspended_orders: response.data.suspended_completed_orders.results }));
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOwnFFOrdersToShipAll = (
    cookies: Cookies,
    shipMarket: string,
    searchTerm: string,
    sortBy: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    setProductAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            let response = await orderToShip.getOwnFFOrdersToShipAll(cookies, shipMarket, searchTerm, sortBy, items, offset);
            setProductAllNext(!!response.data.completed_orders.next);
            setProductAllSuspendedNext(!!response.data.suspended_completed_orders.next);
            dispatch(SetOrdersToShipAll("own_ff", { orders: response.data.completed_orders.results, suspended_orders: response.data.suspended_completed_orders.results }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchProofsOwnFFOrderToShip = (cookies: Cookies, orderId: string, data: FormData, shipMarket: string, hideNotification?: () => void): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));
            await orderToShip.patchProofsOwnFFOrderToShip(cookies, orderId, data);
            if (hideNotification) {
                hideNotification();
            }
            dispatch(getOwnFFOrdersToShip(cookies, shipMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
                if (hideNotification) {
                    hideNotification();
                }
                displayError(e.response.data);
            }
        }
    };
};

export const postCompletedOwnFFOrderToShip = (cookies: Cookies, data: TPostCompletedOwnFFOrderToShip, shipMarket: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));
            await orderToShip.postCompletedOwnFFOrderToShip(cookies, data);
            dispatch(getOwnFFOrdersToShip(cookies, shipMarket));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
                displayError(e.response.data);
            }
        }
    };
};

// Orders to Ship: Bulk orders management

export const getOrdersToShipBulkManagement = (
    cookies: Cookies,
    mode: "picked_up" | "loaded" | "verification_file",
    shipMarket: TWarehouseMarkets,
    searchTerm: string,
    sortBy: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            const response = await orderToShip.getOrdersToShipBulkManagement(cookies, mode, shipMarket, searchTerm, sortBy, items, offset);
            setProductAllNext(response.data.next !== null ? true : false);
            dispatch(SetOrdersToShipBulkManagement(mode === "verification_file" ? "verification_file" : "bulk_management", response.data.results));
        } catch (e) {
            console.log(e);
        }
    };
};

// Orders to Ship: Verification file

export const postOrdersToShipVerificationFile = (data: TPostOrdersToShipVerificationFile): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));

            const response = await orderToShip.postOrdersToShipVerificationFile(cookies, data);

            window.open(response.data.url, "_blank");

            dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const patchBulkPickedUpOrdersToShip = (cookies: Cookies, shipMarket: string, data: TPatchBulkPickedUpOrdersToShipReqData): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));
            const response = await orderToShip.patchBulkPickedUpOrdersToShip(cookies, data);
            await dispatch(getOrdersToShip(cookies, shipMarket));

            if (response.status === 207) {
                displayToastMessage({ data: response.data, tostOptions: { type: "warning" } });
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response?.data) {
                dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
                displayToastMessage({ data: e.response.data, tostOptions: { type: "error" } });
            }
        }
    };
};

export const patchBulkLoadedOrdersToShip = (
    cookies: Cookies,
    data: TPatchBulkLoadedOrdersToShipReqData,
    activeMarkets: {
        shipMarket: TWarehouseMarkets;
        trackingMarket: TWarehouseMarkets;
        tariffMarket: TWarehouseMarkets;
    }
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));
            const response = await orderToShip.patchBulkLoadedOrdersToShip(cookies, data);
            dispatch(getOrdersToShip(cookies, activeMarkets.shipMarket));
            dispatch(getTrackingIdList(cookies, activeMarkets.trackingMarket));
            dispatch(getTariffList(cookies, null, activeMarkets.tariffMarket));

            if (response.status === 207) {
                displayToastMessage({ data: response.data, tostOptions: { type: "warning" } });
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response?.data) {
                dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
                displayToastMessage({ data: e.response.data, tostOptions: { type: "error" } });
            }
        }
    };
};

// Orders to Prep: Bulk orders management

export const getOrdersToPrepBulkManagement = (
    cookies: Cookies,
    prepMarket: string,
    search: string,
    sortBy: string,
    items: number,
    offset: number,
    productsAllNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            let response = await orderToPrep.getOrdersToPrepAll(cookies, prepMarket, search, sortBy, items, offset);
            productsAllNext(!!response.data.prep_orders.next);
            dispatch(SetOrdersToPrepBulkManagement(response.data.prep_orders.results));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postBulkCompletedOrdersToPrep = (
    markets: {
        prepMarket: TWarehouseMarkets;
        shipMarket: TWarehouseMarkets;
    },
    data: TPostBulkCompletedOrdersToPrep
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));

            const response = await orderToPrep.postBulkCompletedOrdersToPrep(cookies, data);

            dispatch(getOrdersToPrep(cookies, markets.prepMarket));
            dispatch(getOrdersToShip(cookies, markets.shipMarket));

            if (response.status === 207) {
                displayToastMessage({ data: response.data, tostOptions: { type: "warning" } });
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingOrdersToPrep: false }));
                displayError(e.response.data);
            }
        }
    };
};

// Orders to Prep: Manual Quoting

export const getOrdersToPrepQuoting = (): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));

            let response = await orderToPrep.getOrdersToPrepQuoting(cookies, { ordering: ADMIN_TABS_DEFAULT_ORDERING });

            dispatch(SetOrdersToPrep("quote_orders", { orders: response.data.prep_orders.results, suspended_orders: response.data.suspended_prep_orders.results }));
            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOrdersToPrepQuotingAll = (
    queryParams: TRequestLazySearchParams & Partial<{ ordering: TWarehouseDashboardOrdering }>,
    setProductsAllNext: (value: boolean) => void,
    setProductsAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            let response = await orderToPrep.getOrdersToPrepQuotingAll(cookies, queryParams);

            setProductsAllNext(!!response.data.prep_orders.next);
            setProductsAllSuspendedNext(!!response.data.suspended_prep_orders.next);

            dispatch(SetOrdersToPrepAll("quote_orders", { orders: response.data.prep_orders.results, suspended_orders: response.data.suspended_prep_orders.results }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

export const postCompletedQuoteOrderToPrep = (data: TPostCompletedQuoteOrderToPrep): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;
            const ordersToShipFilter = getState().warehouse.warehouseFilters.filtersFF.ordersToShip;

            dispatch(SetLoadingStates({ isLoadingOrdersToPrep: true }));

            await orderToPrep.postCompletedQuoteOrderToPrep(cookies, data);

            dispatch(getOrdersToPrepQuoting());

            if (ordersToShipFilter === "quote_orders") {
                dispatch(getOrdersToShipQuoting());
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

// Orders to Ship: Manual Quoting

export const getOrdersToShipQuoting = (): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));

            let response = await orderToShip.getOrdersToShipQuoting(cookies, { ordering: ADMIN_TABS_DEFAULT_ORDERING });

            dispatch(SetOrdersToShip("quote_orders", { orders: response.data.orders_to_ship.results, suspended_orders: response.data.suspended_orders_to_ship.results }));
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchQuotePickedUpOrdersToShip = (cookies: Cookies, id: string): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));

            await orderToShip.patchQuotePickedUpOrdersToShip(cookies, id);

            dispatch(getOrdersToShipQuoting());
        } catch (e) {
            console.log(e);
        }
    };
};

export const patchQuoteLoadedOrdersToShip = (data: TPatchQuoteLoadedOrdersToShipReqData): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            const { warehouseFilters } = getState().warehouse;

            dispatch(SetLoadingStates({ isLoadingOrdersToShip: true }));

            await orderToShip.patchQuoteLoadedOrdersToShip(cookies, data);

            dispatch(getOrdersToShipQuoting());

            if (warehouseFilters.filtersFF.trackingIdEntry === "quote_orders") {
                dispatch(getTrackingIdQuoting());
            }

            if (warehouseFilters.filtersFF.tariffManagement === "quote_orders") {
                dispatch(getTariffManagementQuoteOrders());
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOrdersToShipQuotingAll = (
    queryParams: TRequestLazySearchParams & Partial<{ ordering: TWarehouseDashboardOrdering }>,
    setProductsAllNext: (value: boolean) => void,
    setProductsAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            let response = await orderToShip.getOrdersToShipQuotingAll(cookies, queryParams);

            setProductsAllNext(!!response.data.orders_to_ship.next);
            setProductsAllSuspendedNext(!!response.data.suspended_orders_to_ship.next);

            dispatch(SetOrdersToShipAll("quote_orders", { orders: response.data.orders_to_ship.results, suspended_orders: response.data.suspended_orders_to_ship.results }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
            }
        }
    };
};

// Tracking ID: Manual Quoting

export const getTrackingIdQuoting = (): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));

            let response = await trackingId.getTrackingIdQuoting(cookies, { ordering: ADMIN_TABS_DEFAULT_ORDERING });

            dispatch(SetTrackingIdList("quote_orders", { orders: response.data.orders_to_track.results, suspended_orders: response.data.suspended_orders_to_track.results }));

            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getTrackingIdQuotingAll = (
    queryParams: TRequestLazySearchParams & Partial<{ ordering: TWarehouseDashboardOrdering }>,
    setProductsAllNext: (value: boolean) => void,
    setProductsAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            let response = await trackingId.getTrackingIdQuotingAll(cookies, queryParams);

            setProductsAllNext(!!response.data.orders_to_track.next);
            setProductsAllSuspendedNext(!!response.data.suspended_orders_to_track.next);

            dispatch(SetTrackingIdListAll("quote_orders", { orders: response.data.orders_to_track.results, suspended_orders: response.data.suspended_orders_to_track.results }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const setQuoteShippedDate = (cookies: Cookies, id: string, data: SetShippedDateType): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));

            await trackingId.setQuoteShippedDate(cookies, id, data);

            dispatch(getTrackingIdQuoting());
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: false }));
                displayError(e.response.data, "Something went wrong while updating the shipped date");
            }
        }
    };
};

export const setQuoteTrackingIdList = (cookies: Cookies, data: SetQuoteTrackingIdListType): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));

            await trackingId.setQuoteTrackingIdList(cookies, data);

            dispatch(getTrackingIdQuoting());
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const updateQuoteTrackingIdList = (cookies: Cookies, data: TPatchUpdateQuoteTrackingIdsReqData): WarehouseThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: true }));

            await trackingId.updateQuoteTrackingIdLis(cookies, data);

            dispatch(getTrackingIdQuoting());
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTrackingIdEntry: false }));
                displayError(e.response.data);
            }
        }
    };
};

// Tariff Management: Manual Quoting

export const getTariffManagementQuoteOrders = (): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));

            let response = await tariffList.getTariffManagementQuoteOrders(cookies, { ordering: ADMIN_TABS_DEFAULT_ORDERING });

            dispatch(SetTariffManagement("quote_orders", { orders: response.data.orders_to_manage.results, suspended_orders: response.data.suspended_orders_to_manage.results }));

            dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getTariffManagementQuoteOrdersAll = (
    queryParams: TRequestLazySearchParams & Partial<{ ordering: TWarehouseDashboardOrdering }>,
    setProductsAllNext: (value: boolean) => void,
    setProductsAllSuspendedNext: (value: boolean) => void
): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            let response = await tariffList.getTariffManagementQuoteOrders(cookies, queryParams);

            dispatch(
                SetTariffManagementAll("quote_orders", { orders: response.data.orders_to_manage.results, suspended_orders: response.data.suspended_orders_to_manage.results })
            );

            setProductsAllNext(!!response.data.orders_to_manage.next);
            setProductsAllSuspendedNext(!!response.data.suspended_orders_to_manage.next);
        } catch (e) {
            console.log(e);
        }
    };
};

export const postSaveQuoteCartonTariff = (cartonId: string, reqData: { tariff_amount: number }): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));

            await tariffList.postSaveQuoteCartonTariff(cookies, cartonId, reqData);

            dispatch(getTariffManagementQuoteOrders());
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const postChargeQuoteOrderTariff = (reqData: { outgoing_shipment_ids: string[] }): WarehouseThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingTariffManagement: true }));

            await tariffList.postChargeQuoteOrderTariff(cookies, reqData);

            dispatch(getTariffManagementQuoteOrders());

            displaySuccess("The tariff was successfully charged");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingTariffManagement: false }));
                displayError(e.response.data);
            }
        }
    };
};

export default warehouseReducer;
